// 格式：XXXX年XX月XX日XX时XX分XX秒 星期X
const complement = function (value) {
  return value < 10 ? `0${value}` : value;
};

export const formateDate = (date) => {
  const time = new Date(date);
  const year = time.getFullYear();
  const month = complement(time.getMonth() + 1);
  const day = complement(time.getDate());
  return `${month}/${day}/${year}`;
};

export const groupBy = (array, f) => {
  let groups = {};
  array.forEach(function (o) {
    let group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
};

//转意符 换成普通字符
export function escape2Html(str) {
  var arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
  return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
    return arrEntities[t]
  })
}

/*移除HTML标签代码*/
export function removeHTMLTag(str, length = 0) {
  str = str.replace(/<\/?[^>]*>/g, '') //去除HTML tag
  str = str.replace(/[ | ]*\n/g, '\n') //去除行尾空白
  //str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
  str = str.replace(/ /gi, '') //去掉

  //如果展示指定长度的字符，并使用省略号
  if (length > 0) {
    if (length < str.length) {
      return str.slice(0, length) + '......'
    }
  }
  return str
}
